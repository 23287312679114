/* eslint-disable no-underscore-dangle */
import { Fragment } from 'react'

import { Carousel } from 'src/components/Carousel/Carousel'
import { Container } from 'src/components/Container/Container'
import { EntrypointCard } from 'src/components/HomepageEntrypoints/EntrypointCard'
import { EntrypointTextCard } from 'src/components/HomepageEntrypoints/EntrypointTextCard'
import { config } from 'src/config'

import styles from './EntrypointCardCarousel.module.scss'

import type {
  EntrypointRow as EntrypointRowInterface,
  EntrypointCard as EntrypointCardInterface,
} from 'src/types/HomepageContent'

export interface EntrypointCardCarouselProps extends EntrypointRowInterface {}

export const EntrypointCardCarousel = ({ cardsCollection: { items } }: EntrypointCardCarouselProps) => (
  <Container className={styles.container}>
    <Carousel
      variant="products"
      mobileBreakpoint={config.breakpoints.xl}
      mobileInitialSlide={1}
      slideSize={{ base: '90%', md: '20%' }}
    >
      {items.map((card) => (
        <Fragment key={card.cardTitle}>
          {card.__typename === 'EntryPointCard' ? (
            <EntrypointCard {...(card as EntrypointCardInterface)} />
          ) : (
            <EntrypointTextCard {...card} />
          )}
        </Fragment>
      ))}
    </Carousel>
  </Container>
)
